.home__base {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
}

.home__title {
  #{&}__text {
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 2rem;
  }
}
.home__image {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  background: url(../Images/Welcome-Screen.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  border: solid rgba(255, 255, 255, 0.23) 1px;

  #{&}__bg {
    color: white;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 3%;
    padding-bottom: 3%;
    // border: solid rgba(255, 255, 255, 0.23) 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #{&}__logo {
    height: 210px;
    margin: 0;
    padding: 0;
  }

  #{&}__message {
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
    height: 0vh;
    margin-bottom: 0;
    padding: 0;
  }
}

.home__footer {
  padding: 30px;
}

@media (max-width: 1023px) {
  .home__title {
    #{&}__text {
      font-size: 1.8rem;
    }
  }
}
@media (max-width: 767px) {
  .home__title {
    #{&}__text {
      font-size: 1.2rem;
    }
  }
  .home__image {
    #{&}__logo {
      height: 110px;
    }

    #{&}__message {
      font-size: 0.6rem;
    }
  }

  .home__footer {
    padding: 15px;

    #{&}__logo {
      height: 30px;
    }
  }
}
